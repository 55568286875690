import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name : string="";
  email:string="" ;
  tel:any = "" ;
  mesg:string = "";
  id:any = "";
  data:any = {};
  FormValidation:any ;

  @ViewChild('contctForm', {static: true}) contctForm: NgForm;


  constructor(private spinner: NgxSpinnerService,
    public translate: TranslationService,
    private sharedService: SharedService,
    private pagesService: PagesService
    ) {}




  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  handelSubmit = () =>{

   this.pagesService.postContactUs(this.contctForm.value).subscribe(
      (response: any) => {
        this.data = response.data;
        console.log("caravan",this.data);
        this.sharedService.stringErrorMessage.next('تم الارسال بنجاح :)');
        this.sharedService.errorShowMessage.next(true);
        this.sharedService.hideErrorMessage();
        this.contctForm.reset();
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
