<div class="container my-4">
    <div class="row">
      <div class="col-12 header">
        <p >{{translate.data.contactUs.header.title}}</p>
      </div>
      <div class="body ">
        <div class="row justify-content-evenly flex-wrap ">
          <div class="col-lg-5 col-12 ">
            <div class="img">
              <img src="../../../assets/Contact/Group 10490/Group 10490.png" alt="">
            </div>
          </div>


          <div class=" col-lg-5 col-12  ">
            <!-- #FormValidation = "ngForm" -->
            <form class="form g-3 needs-validation" (submit)="handelSubmit()" #contctForm="ngForm">
              <h3>{{translate.data.contactUs.form.title}}</h3>
              <div class="line"></div>
              <!-- <p>{{translate.data.contactUs.form.subTitle}}</p> -->
              <div class="form-floating mb-3">
                <input type="text"
                      class="form-control input"
                      id="floatingInput1"
                      placeholder="{{translate.data.contactUs.form.userName}}"
                      name="name" ngModel required>
                      <label for="floatingInput1">{{translate.data.contactUs.form.userName}}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="email"
                  class="form-control input"
                  id="floatingInput2"
                  placeholder="{{translate.data.contactUs.form.emailAddress}}"
                  name="email" email="true" #email="ngModel" ngModel required >
                <label for="floatingInput2">{{translate.data.contactUs.form.emailAddress}}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="tel"
                 class="form-control input"
                  id="floatingInput3"
                  placeholder="{{translate.data.contactUs.form.mobileNumber}}"
                  name="phone"
                  ngModel required >
                <label for="floatingInput3">{{translate.data.contactUs.form.mobileNumber}}</label>
              </div>
              <div class="form-floating">
                <textarea class="form-control mb-4 input"
                 placeholder="{{translate.data.contactUs.form.message}}"
                  id="floatingTextarea2"
                  style="height: 100px"
                  required name="message" ngModel></textarea>
                <label for="floatingTextarea2">{{translate.data.contactUs.form.message}}</label>
              </div>
              <button type="submit" [disabled]="!contctForm.valid">
                {{translate.data.contactUs.form.button}}
              </button>
            </form>
          </div>


          <div class="  col-12">
            <div class="footer">
              <p class="text1 ">{{translate.data.contactUs.footer.title}}</p>
                <div class="line"></div>
                <p class="text2">{{translate.data.contactUs.footer.description}}</p>
                <p class="text2">{{translate.data.contactUs.footer.contactNow}}</p>
                <a href="https://api.whatsapp.com/send?phone=00966558088090">
                  <img class="whatSappIcon" src="../../../assets/whatsappIcon/Group 10491.svg" alt="whatsapp">
                </a>
                <p class="text2 d-inline m-0 mt-2 fw-bold">00966558088090</p>
          </div>

        </div>
      </div>
    </div>
</div>
</div>
<router-outlet></router-outlet>
